<template>
  <div v-if="burgerActive" class="absolute h-screen z-30 bg-black w-full md:w-420 shadow-lg overflow-y-auto right-0" :style="{ 'max-height': `${burgerMaxHeight}px` }">
    <div class="lg:hidden py-20 px-10 border-b border-black bg-white">
      <SearchForm @search="closeBurger" />
    </div>
    <nav class="relative">
      <div class="max-w-1225 mx-auto relative">
        <ul class="flex flex-wrap justify-between">
          <template v-for="(item, index) in menu" :key="item.slug + index">
            <li v-if="item.slug" class="px-50 py-20 w-full bg-white border-b border-grey-100">
              <div class="flex items-center justify-between overflow-hidden">
                <NuxtLink v-if="item.slug.indexOf('https://') < 0" class="mt-4 font-khand font-bold text-28 leading-42 tracking-[0.56px] uppercase hover:text-red-400" :to="item.slug" v-on:click.native="closeBurger">{{ item.title }}</NuxtLink>
                <a v-else target="_blank" class="mt-4 font-khand font-bold text-28 leading-42 tracking-[0.56px] uppercase hover:text-red-400" :href="item.slug">{{ item.title }}</a>
                <button v-if="item.children?.length" @click="toggleMenuIndex(index)" class="w-20 h-20 square-20 focus:outline-none bg-center bg-no-repeat transition-transform transform duration-300 bg-svg-arrow2-black" :class="{ 'rotate-180': activeMenuIndex(index) }" aria-label="Udfold menu"></button>
              </div>
              <transition name="slide">
                <ul class="px-20 py-3" v-if="item.children && activeMenuIndex(index)">
                  <li v-for="childItem in item.children" :key="childItem.title">
                    <NuxtLink v-if="childItem.slug.indexOf('https://') < 0" class="text-24 block leading-36 font-khand mt-5 uppercase hover:text-red-400" :to="childItem.slug" v-on:click.native="closeBurger">{{ childItem.title }}</NuxtLink>
                    <a v-else :href="childItem.slug" target="_blank" rel="noreferrer" class="text-24 block leading-36 font-khand mt-5 uppercase hover:text-red-400">{{ childItem.title }}</a>
                  </li>
                </ul>
              </transition>
            </li>
          </template>
          <template v-for="(item, index) in secondaryMenu" :key="item.slug + index">
            <li v-if="item.slug" class="px-50 py-20 w-full border-b border-grey-300">
              <NuxtLink v-if="item.slug.indexOf('https://') < 0" class="flex justify-between items-center text-white font-khand font-bold text-28 leading-42 tracking-[0.56px] uppercase hover:text-red-400" :to="item.slug" v-on:click.native="closeBurger" no-prefetch>{{ item.title }}</NuxtLink>
              <a v-else target="_blank" class="flex justify-between items-center text-white font-khand font-bold text-28 leading-42 tracking-[0.56px] uppercase hover:text-red-400" :href="item.slug">{{ item.title }}</a>
            </li>
          </template>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script setup lang="ts">
defineProps({
  burgerMaxHeight: {
    type: Number,
  },
  burgerActive: {
    type: Boolean,
  },
});

const emit = defineEmits<{ (e: 'close'): void }>();
const { public: config } = useRuntimeConfig();

/**
 * Emit close signal, to close the burger menu.
 *
 * @returns void
 */
const closeBurger = () => {
  emit('close');
};

/**
 * Get the menu
 *
 * @type Types.MenuItem[]
 */
const menu = ref<Aller.MenuItem[]>();

/**
 * Get the secondary menu
 *
 * @type Types.MenuItem[]
 */
const secondaryMenu = ref<Aller.MenuItem[]>();

/**
 * Get the menu
 *
 */
try {
  const { data }: { data: any } = await useFetch(`https://${config.dataApiUrl}/api/v1/menu`);

  if (data.value.primary) {
    menu.value = data.value.primary;
  }
  if (data.value.secondary.length > 0) {
    secondaryMenu.value = data.value.secondary;
  }
} catch (err) {
  console.error('Backend failed to get menu with error:', err);
}

/**
 * Currently 'active' menu items'.
 *
 * @type {Ref<UnwrapRef<number[]>>}
 */
const activeMenuIndexes = ref([] as number[]);

/**
 * Determine if a given index is in the activeMenuIndexes.
 *
 * @param {number} index
 * @returns {boolean}
 */
const activeMenuIndex = (index: number) => {
  return !!activeMenuIndexes.value.includes(index);
};

/**
 * Toggle if an index in the menu should be 'active'.
 *
 * @param {number} index
 */
const toggleMenuIndex = (index: number) => {
  activeMenuIndex(index)
    ? (activeMenuIndexes.value = activeMenuIndexes.value.filter((value) => {
        return value !== index;
      }))
    : activeMenuIndexes.value.push(index);
};
</script>

<style lang="css" scoped>
.slide-enter-active {
  transition: all 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  transform-origin: left top;
}

.slide-leave-active {
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform-origin: left top;
}

.slide-enter-from,
.slide-leave-to {
  transform: scaleY(0);
  opacity: 0;
}
</style>
