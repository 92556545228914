<template>
  <Html lang="da">

    <Head>
      <Meta charset="utf-8" />

      <Link rel="icon" href="/favicon.svg" />
      <Link rel="apple-touch-icon" href="/apple-touch-icon.png" sizes="180x180" />
      <Link rel="icon" type="image/png" href="/favicon-32x32.png" sizes="32x32" />
      <Link rel="icon" type="image/png" href="/favicon-16x16.png" sizes="16x16" />
      <Link rel="manifest" href="/manifest.json" />
      <Link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      <Link rel="stylesheet" href="/css/webfonts-secondary.css" media="print" onload="this.media='all'" />
      <Link rel="preload" href="/fonts/roboto-v29-latin-regular.woff2" crossorigin="anonymous" as="font" type="font/woff2" />
      <Link rel="preload" href="/fonts/khand-v12-latin-700.woff2" crossorigin="anonymous" as="font" type="font/woff2" />
    </Head>

  </Html>
</template>
