<template>
  <header id="site-header" ref="siteHeader" class="sticky top-[-1px] left-0 right-0 z-50">
    <div class="shadow-lg bg-black relative z-70 transition-all duration-500" :class="[!scrolled ? 'h-75' : 'h-50', { 'bg-yellow-400': newsTickerHeaderActive }]">
      <WidthWrapper class="flex justify-start items-center relative" background-color="transparent">
        <div class="-mb-5 mr-auto transition-dimensions duration-500 h-80" :class="!scrolled ? 'overflow-hidden w-[90px] md:w-[100px]' : '!h-55 w-[65px] md:w-70'">
          <NuxtLink class="transition-all ml-5 md:ml-20 block relative duration-500" :class="scrolled ? 'square-55' : 'square-75 rotate-[-7deg]'" to="/" @click.native="scrollTop" aria-label="Gå til forsiden">
            <span class="h-20 bg-[#B90E16] absolute inset-x-0 top-[-19px]"></span>
            <i class="bg-svg-logo-black bg-center bg-no-repeat block h-full w-full backface-hidden transform-preserve-3d"></i>
          </NuxtLink>
        </div>
        <div id="newsRibbonHeaderContainer" class="flex-1 relative overflow-hidden self-stretch flex items-center mr-10 px-2">
          <div v-if="newsTickerHeaderActive" class="fadeIn w-10 h-full absolute top-0 -mr-5 right-5 bg-gradient-to-l from-yellow-400 to-transparent z-10" />
          <div v-if="newsTickerHeaderActive" class="fadeIn w-10 h-full absolute top-0 -ml-5 left-5 md:left-20 bg-gradient-to-r from-yellow-400 to-transparent z-10" />
        </div>
        <button aria-label="Søg" @click="toggleActiveSearch" class="hidden lg:block square-50 p-12 mr-30">
          <i class="bg-center bg-no-repeat h-full w-full block" :class="{ 'bg-svg-x-white': searchActive && !newsTickerHeaderActive, 'bg-svg-search-white': !searchActive && !newsTickerHeaderActive, 'bg-svg-x-black': searchActive && newsTickerHeaderActive, 'bg-svg-search-black': !searchActive && newsTickerHeaderActive }"></i>
        </button>
        <button aria-label="Menu" @click="toggleActiveBurger" class="square-45 sm:square-50 p-7 py-12 focus:outline-none mr-10">
          <i class="bg-center bg-no-repeat h-full w-full block" :class="{ 'bg-svg-x-white': burgerActive && !newsTickerHeaderActive, 'bg-svg-burger-white': !burgerActive && !newsTickerHeaderActive, 'bg-svg-x-black': burgerActive && newsTickerHeaderActive, 'bg-svg-burger-black': !burgerActive && newsTickerHeaderActive }"></i>
        </button>
      </WidthWrapper>
    </div>
    <transition name="slide">
      <LazySiteSearch v-if="searchActive" :search-active="searchActive" @close="closeOverlay" />
    </transition>
    <transition name="burgerSlide">
      <SiteMenuBurger :burgerMaxHeight="burgerMaxHeight" :burgerActive="burgerActive" @close="closeOverlay" />
    </transition>
    <transition name="fade">
      <div v-if="searchActive || burgerActive" class="bg-black-100 w-full h-full fixed z-10 top-[0px]" @click="closeOverlay"></div>
    </transition>
  </header>
</template>

<script setup lang="ts">
/**
 * Current route.
 */
const route = useRoute();

/**
 * Get the current state of the burger menu.
 */
const burgerActive = ref(false);

/**
 * The maximum height, the burgermenu can consume.
 */
const burgerMaxHeight = ref(0);

/**
 * Indicates if a newsticker is currently visible in the header.
 */
const newsTickerHeaderActive = useState('newsTickerHeaderActive', () => false);

/**
 * Toggles the burgermenu active state.
 *
 * @returns void
 */
const toggleActiveBurger = () => {
  if (siteHeader && siteHeader.value) {
    burgerActive.value = !burgerActive.value;
    searchActive.value = false;
    burgerMaxHeight.value = window.innerHeight - (siteHeader.value.getBoundingClientRect().top + siteHeader.value.getBoundingClientRect().height) + 7;
  }
};

/**
 * Get the current state of the search.
 */
const searchActive = ref(false);

/**
 * Toggles the searchform state.
 *
 * @returns void
 */
const toggleActiveSearch = () => {
  burgerActive.value = false;
  searchActive.value = !searchActive.value;
  if (searchActive.value) {
    setTimeout(() => {
      document.getElementById('globalSearch')?.focus();
    }, 300);
  }
};

/**
 * Scroll top, frontpage will animate the scroll.
 *
 * @returns void
 */
const scrollTop = () => {
  burgerActive.value = false;
  window.scrollTo({ top: 0, behavior: route.fullPath === '/' ? 'smooth' : 'auto' });
};

/**
 * Close the overlays.
 *
 * @returns void
 */
const closeOverlay = () => {
  burgerActive.value = false;
  searchActive.value = false;
}

const siteHeader = ref<HTMLElement>();
/**
 * Observer used to handle scroll.
 *
 * @type ResizeObserver|null
 */
let observer: ResizeObserver | null = null;

const scrolled = ref(false);


/**
 * Toggle body scroll when burgerActive changes.
 */
const bodyOverflowClass = computed(() => burgerActive.value ? 'overflow-hidden lg:overflow-auto' : '');
useHead({
  bodyAttrs: {
    class: bodyOverflowClass,
  },
});

/**
 * Vue mounted hook.
 *
 */
onMounted(() => {
  // Detect scrolled.
  if (siteHeader.value) {
    observer = new IntersectionObserver(([e]) => {
      scrolled.value = e.intersectionRatio < 1;
    }, { threshold: [1] });
    observer.observe(siteHeader.value);
  }
});
</script>

<style lang="css" scoped>
.fadeIn {
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.slide-enter-active {
  transition: all 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.slide-leave-active {
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.slide-enter-from,
.slide-leave-to {
  transform: translateY(-100%);
}

.burgerSlide-enter-active {
  transition: all 0.2s cubic-bezier(0.01, 0.045, 0.60, 0.80);
}

.burgerSlide-leave-active {
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.burgerSlide-enter-from,
.burgerSlide-leave-to {
  transform: translateX(100%);
}

.fade-enter-active {
  transition: all 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.fade-leave-active {
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
