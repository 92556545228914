<template>
  <AllerAd v-if="!isNaughtyPage || allowNaughtyAd" v-bind="adProps" />
</template>

<script setup lang="ts">
const props = defineProps({
  ad: {
    type: Object as PropType<Aller.Ad>,
    required: true,
  },
  greyBackground: {
    type: Boolean,
    default: false,
  },
  allowNaughtyAd: {
    type: Boolean,
    default: false,
  },
});

const route = useRoute();

/**
 * Conditional props for the AllerAd component
 */
const adProps: { ad: Aller.Ad, 'inner-class'?: String } = {
  ad: props.ad,
  adClasses: { 'min-h-[300px] md:min-h-[250px]': props.ad.slotId.indexOf('_top') < 0 }
};
if (props.greyBackground) {
  adProps['inner-class'] = '!bg-grey-200 lg:!bg-white !-mx-20 !lg:mx-0 !flex !justify-center lg:!flex-none lg:!border-grey-200 lg:!border-10 !py-10 lg:!py-0';
}

/**
 * Returns if the ad is on a naughty page
 */
const isNaughtyPage = route?.fullPath?.includes('/det-fraekke');
</script>