<template>
  <div class="flex justify-center" :class="outerClasses">
    <form class="flex w-full items-center" :class="formClasses" action="soeg" method="get" @submit.prevent="search()">
      <div class="search-input">
        <input type="search" :id="id" v-model="searchTerm" placeholder=" " />
        <label :for="id">Skriv søgeord</label>
      </div>
      <slot>
        <button :disabled="!searchTerm" type="submit" aria-label="Søg" class="-m-px bg-red-600 hover:bg-red-400 square-40 text-center focus:outline-none">
          <i class="bg-center bg-no-repeat bg-svg-search-white pr-20"></i>
        </button>
      </slot>
    </form>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  id: {
    type: String,
    default: 'search'
  },
  outerClasses: {
    type: String,
    default: 'w-full'
  },
  formClasses: {
    type: String,
    default: 'border border-grey-400'
  },
  searchTermPrefill: {
    type: String,
    default: ''
  }
});

const searchTerm = ref(props.searchTermPrefill);

const watchSearchTerm = watch(() => props.searchTermPrefill, (newVal) => {
  searchTerm.value = newVal;
});

const emit = defineEmits(['search']);
const router = useRouter();

const search = () => {
  emit('search');
  router.push(`/soeg?q=${searchTerm.value}`);
};

onBeforeUnmount(() => {
  watchSearchTerm();
});
</script>

<style lang="css" scoped>
.search-input {
  @apply relative flex-grow px-20;
}

.search-input input {
  @apply w-full h-full pr-10 uppercase font-bold;
}

.search-input input::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.search-input input:focus {
  @apply outline-none;
}

.search-input input+label {
  @apply px-20 leading-20 font-light italic uppercase absolute top-0 left-0 text-14 mt-3 transition-all duration-200 ease-linear;
}

.search-input input:not(:placeholder-shown)+label,
.search-input input:focus+label {
  @apply -mx-5 px-5 -mt-20 bg-white capitalize;
}
</style>
