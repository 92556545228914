import { default as _91_46_46_46slug_93pelBYDGXPBMeta } from "/var/www/html/pages/[...slug].vue?macro=true";
import { default as cookies_45declarationH9h8PviyfSMeta } from "/var/www/html/pages/cookies-declaration.vue?macro=true";
import { default as indexUbNeHw0dYYMeta } from "/var/www/html/pages/index.vue?macro=true";
import { default as kontaktqqm8YTGBrSMeta } from "/var/www/html/pages/kontakt.vue?macro=true";
import { default as krydsord1Ugjisua0eMeta } from "/var/www/html/pages/krydsord.vue?macro=true";
import { default as nyhedsbrev_45tilmeldtRr3k4IoXdeMeta } from "/var/www/html/pages/nyhedsbrev-tilmeldt.vue?macro=true";
import { default as nyhedsbrevCgzkpsPOjBMeta } from "/var/www/html/pages/nyhedsbrev.vue?macro=true";
import { default as plingZt6Kf738RUMeta } from "/var/www/html/pages/pling.vue?macro=true";
import { default as soeg_45personerxxRpqh1zuWMeta } from "/var/www/html/pages/soeg-personer.vue?macro=true";
import { default as soegh7TcCV9MM4Meta } from "/var/www/html/pages/soeg.vue?macro=true";
import { default as _91_46_46_46slug_93OhCemqkRl3Meta } from "/var/www/html/pages/video/[...slug].vue?macro=true";
import { default as indexmAHoJoR9JjMeta } from "/var/www/html/pages/video/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93pelBYDGXPBMeta?.name ?? "slug",
    path: _91_46_46_46slug_93pelBYDGXPBMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93pelBYDGXPBMeta || {},
    alias: _91_46_46_46slug_93pelBYDGXPBMeta?.alias || [],
    redirect: _91_46_46_46slug_93pelBYDGXPBMeta?.redirect,
    component: () => import("/var/www/html/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: cookies_45declarationH9h8PviyfSMeta?.name ?? "cookies-declaration",
    path: cookies_45declarationH9h8PviyfSMeta?.path ?? "/cookies-declaration",
    meta: cookies_45declarationH9h8PviyfSMeta || {},
    alias: cookies_45declarationH9h8PviyfSMeta?.alias || [],
    redirect: cookies_45declarationH9h8PviyfSMeta?.redirect,
    component: () => import("/var/www/html/pages/cookies-declaration.vue").then(m => m.default || m)
  },
  {
    name: indexUbNeHw0dYYMeta?.name ?? "index",
    path: indexUbNeHw0dYYMeta?.path ?? "/",
    meta: indexUbNeHw0dYYMeta || {},
    alias: indexUbNeHw0dYYMeta?.alias || [],
    redirect: indexUbNeHw0dYYMeta?.redirect,
    component: () => import("/var/www/html/pages/index.vue").then(m => m.default || m)
  },
  {
    name: kontaktqqm8YTGBrSMeta?.name ?? "kontakt",
    path: kontaktqqm8YTGBrSMeta?.path ?? "/kontakt",
    meta: kontaktqqm8YTGBrSMeta || {},
    alias: kontaktqqm8YTGBrSMeta?.alias || [],
    redirect: kontaktqqm8YTGBrSMeta?.redirect,
    component: () => import("/var/www/html/pages/kontakt.vue").then(m => m.default || m)
  },
  {
    name: krydsord1Ugjisua0eMeta?.name ?? "krydsord",
    path: krydsord1Ugjisua0eMeta?.path ?? "/krydsord",
    meta: krydsord1Ugjisua0eMeta || {},
    alias: krydsord1Ugjisua0eMeta?.alias || [],
    redirect: krydsord1Ugjisua0eMeta?.redirect,
    component: () => import("/var/www/html/pages/krydsord.vue").then(m => m.default || m)
  },
  {
    name: nyhedsbrev_45tilmeldtRr3k4IoXdeMeta?.name ?? "nyhedsbrev-tilmeldt",
    path: nyhedsbrev_45tilmeldtRr3k4IoXdeMeta?.path ?? "/nyhedsbrev-tilmeldt",
    meta: nyhedsbrev_45tilmeldtRr3k4IoXdeMeta || {},
    alias: nyhedsbrev_45tilmeldtRr3k4IoXdeMeta?.alias || [],
    redirect: nyhedsbrev_45tilmeldtRr3k4IoXdeMeta?.redirect,
    component: () => import("/var/www/html/pages/nyhedsbrev-tilmeldt.vue").then(m => m.default || m)
  },
  {
    name: nyhedsbrevCgzkpsPOjBMeta?.name ?? "nyhedsbrev",
    path: nyhedsbrevCgzkpsPOjBMeta?.path ?? "/nyhedsbrev",
    meta: nyhedsbrevCgzkpsPOjBMeta || {},
    alias: nyhedsbrevCgzkpsPOjBMeta?.alias || [],
    redirect: nyhedsbrevCgzkpsPOjBMeta?.redirect,
    component: () => import("/var/www/html/pages/nyhedsbrev.vue").then(m => m.default || m)
  },
  {
    name: plingZt6Kf738RUMeta?.name ?? "pling",
    path: plingZt6Kf738RUMeta?.path ?? "/pling",
    meta: plingZt6Kf738RUMeta || {},
    alias: plingZt6Kf738RUMeta?.alias || [],
    redirect: plingZt6Kf738RUMeta?.redirect,
    component: () => import("/var/www/html/pages/pling.vue").then(m => m.default || m)
  },
  {
    name: soeg_45personerxxRpqh1zuWMeta?.name ?? "soeg-personer",
    path: soeg_45personerxxRpqh1zuWMeta?.path ?? "/soeg-personer",
    meta: soeg_45personerxxRpqh1zuWMeta || {},
    alias: soeg_45personerxxRpqh1zuWMeta?.alias || [],
    redirect: soeg_45personerxxRpqh1zuWMeta?.redirect,
    component: () => import("/var/www/html/pages/soeg-personer.vue").then(m => m.default || m)
  },
  {
    name: soegh7TcCV9MM4Meta?.name ?? "soeg",
    path: soegh7TcCV9MM4Meta?.path ?? "/soeg",
    meta: soegh7TcCV9MM4Meta || {},
    alias: soegh7TcCV9MM4Meta?.alias || [],
    redirect: soegh7TcCV9MM4Meta?.redirect,
    component: () => import("/var/www/html/pages/soeg.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93OhCemqkRl3Meta?.name ?? "video-slug",
    path: _91_46_46_46slug_93OhCemqkRl3Meta?.path ?? "/video/:slug(.*)*",
    meta: _91_46_46_46slug_93OhCemqkRl3Meta || {},
    alias: _91_46_46_46slug_93OhCemqkRl3Meta?.alias || [],
    redirect: _91_46_46_46slug_93OhCemqkRl3Meta?.redirect,
    component: () => import("/var/www/html/pages/video/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexmAHoJoR9JjMeta?.name ?? "video",
    path: indexmAHoJoR9JjMeta?.path ?? "/video",
    meta: indexmAHoJoR9JjMeta || {},
    alias: indexmAHoJoR9JjMeta?.alias || [],
    redirect: indexmAHoJoR9JjMeta?.redirect,
    component: () => import("/var/www/html/pages/video/index.vue").then(m => m.default || m)
  }
]