import { Formats } from '@aller/nuxt-base/utils/adTech';

export default defineAppConfig({
  adTech: {
    targeting: {
      betting: 'yes',
    },
    placements: {
      // DEFAUTLt
      default: {
        sizes: [[1, 1]],
        targeting: [{ pos: 'default' }],
      },

      // DESKTOP
      dsk_top_ad: {
        sizes: [[1, 2]],
        targeting: [{ pos: ['top_ad'], format: [Formats.TOPSCROLL_DSK] }],
      },
      dsk_responsive_1: {
        sizes: [[930,180], [930,600], [970,170], [970,270]],
        targeting: [{ pos: ['responsive_1'], format: [Formats.SKIN] }],
      },
      dsk_responsive_2: {
        sizes: [[300,250], [930,180], [930,600], [970,270]],
        targeting: [{ pos: ['responsive_2'] }],
      },
      dsk_responsive_3: {
        sizes: [[300,250], [930,180], [930,600], [970,270], [970,570], [970,550]],
        targeting: [{ pos: ['responsive_3'], format: [Formats.INTERSCROLL_DSK, Formats.DOUBLE_INTERSCROLL_DSK] }],
      },
      dsk_responsive_4: {
        sizes: [[300,250], [930,180], [930,600], [970,270], [970,570]],
        targeting: [{ pos: ['responsive_4'], format: [Formats.INTERSCROLL_DSK] }],
      },
      dsk_responsive_5: {
        sizes: [[300,250], [930,180], [930,600], [970,270]],
        targeting: [{ pos: ['responsive_5'] }],
      },
      dsk_responsive_6: {
        sizes: [[300,250], [930,180], [930,600], [970,270], [970,570]],
        targeting: [{ pos: ['responsive_6'], format: [Formats.INTERSCROLL_DSK] }],
      },
      dsk_responsive_7: {
        sizes: [[300,250], [930,180], [930,600], [970,270]],
        targeting: [{ pos: ['responsive_7'] }],
      },
      dsk_responsive_8: {
        sizes: [[300,250], [930,180], [930,600], [970,270], [970,570]],
        targeting: [{ pos: ['responsive_8'], format: [Formats.INTERSCROLL_DSK] }],
      },
      dsk_responsive_9: {
        sizes: [[300,250], [930,180], [930,600], [970,270]],
        targeting: [{ pos: ['responsive_9'] }],
      },
      dsk_responsive_10: {
        sizes: [[300,250], [930,180], [930,600], [970,270], [970,570]],
        targeting: [{ pos: ['responsive_10'], format: [Formats.INTERSCROLL_DSK] }],
      },
      dsk_responsive_11: {
        sizes: [[300,250], [930,180], [930,600], [970,270]],
        targeting: [{ pos: ['responsive_11'] }],
      },
      dsk_responsive_12: {
        sizes: [[300,250], [930,180], [930,600], [970,270], [970,570]],
        targeting: [{ pos: ['responsive_12'], format: [Formats.INTERSCROLL_DSK] }],
      },
      dsk_intext_ad_bottom: {
        sizes: [[300,250], [930,180], [930,600], [970,270], [970,570], [970,550]],
        targeting: [{ pos: ['intext_ad_bottom'], format: [Formats.INTERSCROLL_DSK, Formats.DOUBLE_INTERSCROLL_DSK] }],
      },
      dsk_intext_ad_1: {
        sizes: [[300,250]],
        targeting: [{ pos: ['intext_ad_1'] }],
      },
      dsk_intext_ad_2: {
        sizes: [[300,250]],
        targeting: [{ pos: ['intext_ad_2'] }],
      },
      dsk_intext_ad_3: {
        sizes: [[300,250]],
        targeting: [{ pos: ['intext_ad_3'] }],
      },
      dsk_intext_ad_4: {
        sizes: [[300,250]],
        targeting: [{ pos: ['intext_ad_4'] }],
      },
      dsk_intext_ad_5: {
        sizes: [[300,250]],
        targeting: [{ pos: ['intext_ad_5'] }],
      },
      dsk_intext_ad_6: {
        sizes: [[300,250]],
        targeting: [{ pos: ['intext_ad_6'] }],
      },
      dsk_intext_ad_7: {
        sizes: [[300,250]],
        targeting: [{ pos: ['intext_ad_7'] }],
      },
      dsk_intext_ad_8: {
        sizes: [[300,250]],
        targeting: [{ pos: ['intext_ad_8'] }],
      },
      dsk_intext_ad_9: {
        sizes: [[300,250]],
        targeting: [{ pos: ['intext_ad_9'] }],
      },
      dsk_rec_ad_1: {
        sizes: [[300,250]],
        targeting: [{ pos: ['rec_ad_1'] }],
      },
      dsk_rec_ad_2: {
        sizes: [[300,250]],
        targeting: [{ pos: ['rec_ad_2'] }],
      },
      dsk_rec_ad_3: {
        sizes: [[300,250]],
        targeting: [{ pos: ['rec_ad_3'] }],
      },
      dsk_rec_ad_4: {
        sizes: [[300,250]],
        targeting: [{ pos: ['rec_ad_4'] }],
      },
      dsk_rec_ad_5: {
        sizes: [[300,250]],
        targeting: [{ pos: ['rec_ad_5'] }],
      },
      dsk_rec_ad_6: {
        sizes: [[300,250]],
        targeting: [{ pos: ['rec_ad_6'] }],
      },
      dsk_rec_ad_7: {
        sizes: [[300,250]],
        targeting: [{ pos: ['rec_ad_7'] }],
      },
      dsk_intext_ad_sticky1: {
        sizes: [[300,250], [300,600]],
        targeting: [{ pos: ['intext_ad_sticky1'] }],
      },
      dsk_intext_ad_sticky2: {
        sizes: [[300,250]],
        targeting: [{ pos: ['intext_ad_sticky2'] }],
      },
      dsk_intext_ad_sticky3: {
        sizes: [[300,250]],
        targeting: [{ pos: ['intext_ad_sticky3'] }],
      },
      dsk_intext_ad_sticky4: {
        sizes: [[300,250]],
        targeting: [{ pos: ['intext_ad_sticky4'] }],
      },
      dsk_intext_ad_sticky5: {
        sizes: [[300,250]],
        targeting: [{ pos: ['intext_ad_sticky5'] }],
      },
      dsk_sky1: {
        sizes: [[160,600], [300,600]],
        targeting: [{ pos: ['sky1'] }],
      },
      dsk_sky2: {
        sizes: [[160,600], [300,600]],
        targeting: [{ pos: ['sky2'] }],
      },
      dsk_sky3: {
        sizes: [[160,600], [300,600]],
        targeting: [{ pos: ['sky3'] }],
      },
      dsk_sky4: {
        sizes: [[160,600], [300,600]],
        targeting: [{ pos: ['sky4'] }],
      },
      dsk_intext_ad_gallery: {
        sizes: [[300,250]],
        targeting: [{ pos: ['intext_ad_gallery'] }],
      },
      nat_dsk_responsive: {
        sizes: [[728,90], [300,250], [930,180], [930,600], [970,570], [970,560]],
        targeting: [{ pos: ['nat_responsive'] }],
      },
      dsk_rec_liveblog: {
        sizes: [[300,250]],
        targeting: [{ pos: ['rec_liveblog'] }],
      },
      'cncpt-dsk_lb1_DF': {
        sizes: [[930,180], [930,600]],
        targeting: [{ pos: ['responsive_1_DF'] }],
      },
      'cncpt-dsk_rec1_DF': {
        sizes: [[300,250]],
        targeting: [{ pos: ['rec1_DF'] }],
      },

      // MOBILE
      mob_top_ad: {
        sizes: [[1,2], [300,220]],
        targeting: [{ pos: ['top_ad'], format: [Formats.TOPSCROLL_MOB] }],
      },
      mob_responsive_1: {
        sizes: [[320,160], [300,250], [320,320], [300,600]],
        targeting: [{ pos: ['responsive_1'] }],
      },
      mob_responsive_2: {
        sizes: [[320,160], [300,250], [320,320], [300,600], [300,240]],
        targeting: [{ pos: ['responsive_2'], format: [Formats.INTERSCROLL_MOB] }],
      },
      mob_responsive_3: {
        sizes: [[320,160], [300,250], [320,320], [300,600], [300,240], [300,210]],
        targeting: [{ pos: ['responsive_3'], format: [Formats.DOUBLE_INTERSCROLL_MOB, Formats.DOUBLE_INTERSCROLL_MOB] }],
      },
      mob_responsive_4: {
        sizes: [[320,160], [300,250], [320,320], [300,600], [300,240]],
        targeting: [{ pos: ['responsive_4'], format: [Formats.DOUBLE_INTERSCROLL_MOB] }],
      },
      mob_responsive_5: {
        sizes: [[320,160], [300,250], [320,320], [300,600], [300,240], [300,210]],
        targeting: [{ pos: ['responsive_5'], format: [Formats.DOUBLE_INTERSCROLL_MOB, Formats.DOUBLE_INTERSCROLL_MOB] }],
      },
      mob_responsive_6: {
        sizes: [[320,160], [300,250], [320,320], [300,600], [300,240]],
        targeting: [{ pos: ['responsive_6'], format: [Formats.DOUBLE_INTERSCROLL_MOB] }],
      },
      mob_responsive_7: {
        sizes: [[320,160], [300,250], [320,320], [300,600], [300,240], [300,210]],
        targeting: [{ pos: ['responsive_7'], format: [Formats.DOUBLE_INTERSCROLL_MOB, Formats.DOUBLE_INTERSCROLL_MOB] }],
      },
      mob_responsive_8: {
        sizes: [[320,160], [300,250], [320,320], [300,600], [300,240]],
        targeting: [{ pos: ['responsive_8'], format: [Formats.DOUBLE_INTERSCROLL_MOB] }],
      },
      mob_responsive_9: {
        sizes: [[320,160], [300,250], [320,320], [300,600], [300,240], [300,210]],
        targeting: [{ pos: ['responsive_9'], format: [Formats.DOUBLE_INTERSCROLL_MOB, Formats.DOUBLE_INTERSCROLL_MOB] }],
      },
      mob_responsive_10: {
        sizes: [[320,160], [300,250], [320,320], [300,600], [300,240]],
        targeting: [{ pos: ['responsive_10'], format: [Formats.DOUBLE_INTERSCROLL_MOB] }],
      },
      mob_responsive_11: {
        sizes: [[320,160], [300,250], [320,320], [300,600], [300,240], [300,210]],
        targeting: [{ pos: ['responsive_11'], format: [Formats.DOUBLE_INTERSCROLL_MOB, Formats.DOUBLE_INTERSCROLL_MOB] }],
      },
      mob_responsive_12: {
        sizes: [[320,160], [300,250], [320,320], [300,600], [300,240]],
        targeting: [{ pos: ['responsive_12'], format: [Formats.DOUBLE_INTERSCROLL_MOB] }],
      },
      mob_responsive_13: {
        sizes: [[320,160], [300,250], [320,320], [300,600], [300,240], [300,210]],
        targeting: [{ pos: ['responsive_13'], format: [Formats.DOUBLE_INTERSCROLL_MOB, Formats.DOUBLE_INTERSCROLL_MOB] }],
      },
      mob_intext_ad_1: {
        sizes: [[320,160], [300,250], [320,320], [300,600], [300,240], [300,210]],
        targeting: [{ pos: ['intext_ad_1'], format: [Formats.INTERSCROLL_MOB, Formats.DOUBLE_INTERSCROLL_MOB] }],
      },
      mob_intext_ad_2: {
        sizes: [[320,160], [300,250], [320,320], [300,600], [300,240]],
        targeting: [{ pos: ['intext_ad_2'], format: [Formats.INTERSCROLL_MOB] }],
      },
      mob_intext_ad_3: {
        sizes: [[320,160], [300,250], [320,320], [300,600], [300,240], [300,210]],
        targeting: [{ pos: ['intext_ad_3'], format: [Formats.INTERSCROLL_MOB] }],
      },
      mob_intext_ad_4: {
        sizes: [[320,160], [300,250], [320,320], [300,600], [300,240], [300,210]],
        targeting: [{ pos: ['intext_ad_4'], format: [Formats.INTERSCROLL_MOB, Formats.DOUBLE_INTERSCROLL_MOB] }],
      },
      mob_intext_ad_5: {
        sizes: [[320,160], [300,250], [320,320], [300,600], [300,240]],
        targeting: [{ pos: ['intext_ad_5'], format: [Formats.INTERSCROLL_MOB] }],
      },
      mob_intext_ad_6: {
        sizes: [[320,160], [300,250], [320,320], [300,600], [300,240]],
        targeting: [{ pos: ['intext_ad_6'], format: [Formats.INTERSCROLL_MOB] }],
      },
      mob_intext_ad_7: {
        sizes: [[320,160], [300,250], [320,320], [300,600], [300,240], [300,210]],
        targeting: [{ pos: ['intext_ad_7'], format: [Formats.INTERSCROLL_MOB, Formats.DOUBLE_INTERSCROLL_MOB] }],
      },
      mob_intext_ad_8: {
        sizes: [[320,160], [300,250], [320,320], [300,600], [300,240]],
        targeting: [{ pos: ['intext_ad_8'], format: [Formats.INTERSCROLL_MOB] }],
      },
      mob_intext_ad_9: {
        sizes: [[320,160], [300,250], [320,320], [300,600], [300,240]],
        targeting: [{ pos: ['intext_ad_9'], format: [Formats.INTERSCROLL_MOB] }],
      },
      mob_intext_ad_bottom: {
        sizes: [[320,160], [300,250], [320,320], [300,600], [300,240]],
        targeting: [{ pos: ['intext_ad_bottom'], format: [Formats.INTERSCROLL_MOB] }],
      },
      mob_nat_responsive: {
        sizes: [[320,160], [300,250], [320,320], [300,600], [300,230], [300,240]],
        targeting: [{ pos: ['nat_responsive'] }],
      },
      mob_liveblog: {
        sizes: [[320,160], [300,250], [320,320]],
        targeting: [{ pos: ['mob_liveblog'] }],
      },
    },
  },
});
