<template>
  <AllerAptoma domain="https://www.seoghoer.dk">
    <template #placeholders="slotProps">
      <AllerReadPeak v-if="slotProps.placeholderMapping === 'readpeak_fullwidth' && route.fullPath !== '/'" />
    </template>
  </AllerAptoma>
</template>

<script setup lang="ts">
const route = useRoute();
</script>
