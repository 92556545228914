<template>
  <WidthWrapper id="adnm-stickies" class="z-5 sticky top-[55px]">
    <div class="-mx-10 relative">
      <div class="absolute right-50p mr-50p top-full hidden towers:block mt-7 w-[300px]">
        <AdWrapper :ad="{ slotId: 'dsk_sky1', stickyUntil: 'data-towers-stop' }" />
      </div>
      <div class="absolute left-50p ml-50p top-full hidden towers:block mt-7 w-[300px]">
        <AdWrapper :ad="{ slotId: 'dsk_sky2', stickyUntil: 'data-towers-stop' }" />
      </div>
    </div>
  </WidthWrapper>
</template>
