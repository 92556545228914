<template>
  <div id="app-root" class="bg-grey-200">
    <SiteHtml />
    <NuxtLoadingIndicator color="repeating-linear-gradient(to right,#0E2557 0%,#EC028C 50%,#E40D2B 100%)" />
    <SiteHeader />
    <TowerAds v-show="!native" />
    <WidthWrapper background-color="transparent">
      <div class="bg-white pb-20">
        <NuxtPage />
      </div>
    </WidthWrapper>
    <LazySiteFooter data-towers-stop="true" />
  </div>
</template>

<script setup lang="ts">
// Inject globalApp, cookiebot, tcf etc.
useAllerGlobalApp();

// Initialize the native property.
const native = useState('native', () => true);

// Load Ad Tech script
useAllerAdTechScript();
</script>
