<template>
  <main class="bg-grey-200">
    <SiteHtml />
    <NuxtLoadingIndicator color="repeating-linear-gradient(to right,#0E2557 0%,#EC028C 50%,#E40D2B 100%)" />
    <SiteHeader />
    <TowerAds />
    <WidthWrapper background-color="transparent">
      <div class="bg-white pb-20 pt-20 relative lg:mt-40">
        <div class="max-w-330 lg:max-w-700 px-20 lg:px-0 lg:mx-auto">
          <h1 class="text-42 lg:text-50 font-khand lg:pr-2 leading-46 lg:leading-55 font-bold mb-30 lg:mb-20">HOV! En af vores spændende artikler er forsvundet...</h1>
          <p class="text-20 pr-10 lg:pr-0 leading-26 font-bold pb-30 lg:pb-60 lg:max-w-600">Vi beklager, at siden er væk, men vi har sørget for en masse indhold til dig her…</p>
        </div>
        <div class="w-[104px] h-[310px] absolute right-15 lg:right-30 -top-60 bg-contain bg-center bg-no-repeat bg-svg-arm-black"></div>
        <Aptoma />
      </div>
    </WidthWrapper>
    <LazySiteFooter data-towers-stop="true" />
  </main>
</template>

<script setup lang="ts">
defineProps({
  error: Object,
})

// Inject globalApp. cookiebot, tcf etc.
useAllerGlobalApp();

// Inject Ad Tech scripts.
useAllerAdTechScript();

onMounted(() => {
  useState('newsTickerHeaderActive').value = false;
});
</script>
